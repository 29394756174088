import React, { useEffect, useState } from 'react';

const AddBackground: React.FC = () => {
    const [image, setImage] = useState<string | null>(null);
    const [error, setError] = useState('');
    const imageTypes = ['image/png', 'image/jpeg'];

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files[0]) {
            if (imageTypes.includes(e.target.files[0].type)) {
                const selected = URL.createObjectURL(e.target.files[0]);
                setImage(selected);
                setError('');
            } else {
                setError('Please select an image file (png or jpeg)');
            }
        } else {
            setError('Please select an image file (png or jpeg)');
        }
    };

    useEffect(() => {
        const background = document.body;
        background.style.backgroundImage = `url(${image})`;
    }, [image]);

    return (
        <form className="background-image-select">
            <div>
                <label>Choose Background Image</label>
                <input
                    required
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={changeHandler}
                />
            </div>
            {error && <div className="error">{error}</div>}
        </form>
    );
};

export default AddBackground;
