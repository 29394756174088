import React, { useState, useEffect } from 'react';

const CurrentDateTime: React.FC = () => {
    const [currentDateTime, setCurrentDateTime] = useState<string>(
        new Date().toLocaleString(),
    );

    // Update the current date and time every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date().toLocaleString());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return <p>{currentDateTime}</p>;
};

export default CurrentDateTime;
