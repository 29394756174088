import React, { useEffect, useState } from 'react';
import { getAllNotes } from '../services/noteService';
import { Note } from '../types/Note';

interface NoteList {
    reload: number;
}

const NoteList: React.FC<NoteList> = ({ reload }) => {
    const [notes, setNotes] = useState<Note[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedColor] = useState<string>('#ffffff'); // Default color

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setLoading(true);
                const notes = await getAllNotes();
                console.log('Fetched notes:', notes);
                if (Array.isArray(notes)) {
                    setNotes(notes);
                } else {
                    setError('Failed to fetch notes');
                }
            } catch (err) {
                console.error('Error fetching notes:', err);
                setError('Failed to fetch notes');
            } finally {
                setLoading(false);
            }
        };
        fetchNotes();
    }, [reload]);

    const handleColorChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        noteId: number,
    ) => {
        const updatedNotes = notes.map((note) =>
            note.id === noteId ? { ...note, color: e.target.value } : note,
        );
        setNotes(updatedNotes);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>All Notes</h2>
            <ul>
                {notes.map((note) => (
                    <li
                        key={note.id}
                        style={{
                            backgroundColor: note.color || '#fff',
                            padding: '10px',
                            margin: '10px 0',
                        }}
                    >
                        <p>{note.text}</p>
                        <label>
                            Choose Color:
                            <input
                                type="color"
                                value={note.color || selectedColor}
                                onChange={(e) => handleColorChange(e, note.id)}
                            />
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NoteList;
