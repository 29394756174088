import React, { useState } from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import AddBackground from './components/AddBackground';
import CurrentDateTime from './components/CurrentDateTime';

const App: React.FC = () => {
    const [reload, setReload] = useState(0);

    const noteAdded = () => {
        setReload((prev) => prev + 1);
    };

    return (
        <div className="App">
            <h1>TODO Notes Application</h1>
            <AddBackground />
            <CurrentDateTime />
            <AddNote onNoteAdded={noteAdded} />
            <NoteList reload={reload} />
        </div>
    );
};

export default App;
